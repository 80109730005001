import React, { useRef, useState, useEffect } from "react";
import * as d3 from "d3";
import { retrieve_chart_data } from "../api";

const LineChart = ({ filters_data, view }) => {
  
  const chartRef = useRef();
  const [chartWidth, setChartWidth] = useState(0);
  const [data, setData] = useState(null);

  // Fetch chart data
  useEffect(() => {
    async function fetchChartData() {
      try {
        const fetchedData = await retrieve_chart_data();
        console.log("Chart Data:", fetchedData);
        setData(fetchedData);
      } catch (error) {
        console.error("Error retrieving chart data:", error);
      }
    }
    fetchChartData();
  }, []);

  // Handle chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const parentWidth = chartRef.current.getBoundingClientRect().width || 500;
        setChartWidth(parentWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update the chart when data, filters_data, or view changes
  useEffect(() => {
    // if (!data) return;

    let filteredData = filters_data && filters_data.length > 0 ? filters_data : data;

    console.log('Filtered Data: ', filteredData)

    if (!filteredData || filteredData.length === 0) {
      console.warn("No data available for chart rendering.");
      // return;
      const now = new Date();
      const year = new Date().getFullYear();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(now.getDate() - 30);

      filteredData = [
        { timestamp: new Date(now.getFullYear(), 0, 1).toISOString() }, // January
        { timestamp: new Date(now.getFullYear(), 1, 1).toISOString() }, // February
        { timestamp: new Date(now.getFullYear(), 2, 1).toISOString() }, // March
        { timestamp: new Date(now.getFullYear(), 3, 1).toISOString() }, // April
        { timestamp: new Date(now.getFullYear(), 4, 1).toISOString() }, // May
      ];

      filteredData = filteredData.filter(({ timestamp }) => {
        const date = new Date(timestamp);
        return date >= thirtyDaysAgo && date <= now;
      });
      

      console.log(filteredData);


  const margin = { top: 50, right: 30, bottom: 30, left: 50 };
  const width = chartWidth - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;

  // Clear existing SVG
  d3.select(chartRef.current).selectAll("*").remove();

  const svg = d3
    .select(chartRef.current)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // X Scale (Placeholder domain for months)
  const x = d3
    .scaleTime()
    .domain([new Date(year, 0, 1), new Date(year, 11, 31)])
    .range([0, width]);

  // Y Scale (Fixed range)
  const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);

  // X-Axis
  svg
    .append("g")
    .attr("transform", `translate(0,${height})`)
    .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b")));

  // Y-Axis
  svg.append("g").call(d3.axisLeft(y));
    }
    else{

    const margin = { top: 50, right: 30, bottom: 30, left: 50 };
    const height = 400 - margin.top - margin.bottom;

    // Clear existing SVG
    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", chartWidth - margin.left - margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // X Scale
    const defaultDomain = [new Date(2024, 0, 1), new Date(2024, 11, 31)];
    const safeFilteredData = Array.isArray(filteredData) ? filteredData : [];

    const x = d3
      .scaleTime()
      .domain(
        safeFilteredData.length > 0
          ? d3.extent(safeFilteredData, (d) => new Date(d.timestamp))
          : defaultDomain // Use a default range when no data is available
      )
      .range([0, chartWidth - margin.left - margin.right]);


    // Y Scale with padding at the bottom (starting point)
    const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    console.log("Filtered Data:", filteredData);

    const tickDates = filteredData.map((d) => new Date(d.timestamp.split(" ")[0]));

    // Axes
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b")).tickValues(tickDates))
      .attr("dy", "30px");

    svg.append("g").call(d3.axisLeft(y).tickValues([0, 25, 50, 75, 100]));

    // Vertical grid lines
    svg
      .selectAll(".vertical-grid")
      .data(tickDates)
      .enter()
      .append("line")
      .attr("class", "vertical-grid")
      .attr("x1", (d) => x(d))
      .attr("y1", 0)
      .attr("x2", (d) => x(d))
      .attr("y2", height)
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4");

    // Horizontal grid lines
    const yTicks = [0, 25, 50, 75, 100];
    svg
      .selectAll(".horizontal-grid")
      .data(yTicks)
      .enter()
      .append("line")
      .attr("class", "horizontal-grid")
      .attr("x1", 0)
      .attr("y1", (d) => y(d))
      .attr("x2", chartWidth - margin.left - margin.right)
      .attr("y2", (d) => y(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4");

    // Line generator
    const line = d3
      .line()
      .x((d) => x(new Date(d.timestamp)))
      .y((d) => y(d.average_score));

    // Draw line
    svg
      .append("path")
      .datum(filteredData)
      .attr("fill", "none")
      .attr("stroke", "#C29C61")
      .attr("stroke-width", 3)
      .attr("d", line);

    // Draw data points
    svg
      .selectAll("circle")
      .data(filteredData)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(new Date(d.timestamp)))
      .attr("cy", (d) => y(d.average_score))
      .attr("r", 4)
      .attr("fill", "#fff")
      .attr("stroke", "#C29C61")
      .attr("stroke-width", 3);
    }
  }, [chartWidth, filters_data, view, data]);

  return <div ref={chartRef}></div>;
};

export default LineChart;
