// import React from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import profileIcon from '../../assets/images/profile-icon.svg';
import { logoutUser } from "../../api";

// import BuilderRules from "../BuilderRules";
import ScoringNotify from '../ScoringNotify';

import { API_BASE_URL, WEB_SOCKET_BASE_URL } from '../../config';
// import BuilderRules from "../BuilderRules";
// import ScoringNotify from '../ScoringNotify';



function Header({ onSelfEvaluationUpdate }) {
    // const {isScoreVisible,renderContent} = BuilderRules();

    const [process, setprocess] = useState(null);
    const [isScoreVisible, setScoreVisible] = useState(false);
    const [selectedBuilder, setSelectedBuilder] = useState(null);
    const [avgScore, setAvgScore] = useState(null);
    const [filters, setFilters] = useState(null);
    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    const email = localStorage.getItem('loggedInUserEmail')?.toLowerCase();
    const isAdmin = localStorage.getItem('is_admin') === 'true';
    const [isEvaluationPopupVisible, setEvaluationPopupVisible] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const closeEvaluationPopup = (e) => {
        e.preventDefault();
        setEvaluationPopupVisible(false);
    };
    const [message, setMessage] = useState('');

    // Function to handle the data from child
    const handleMessageFromChild = (data) => {
        setMessage(data);
    };

    const handleWebSocketMessage = async (data,flag) => {
        const newNotification = { id: Date.now(), text: data };
        if (flag){
            setNotifications((prevNotifications) => {
                const updatedNotifications = [...prevNotifications, newNotification];
                // Limit to a maximum of 5 notifications
                if (updatedNotifications.length > 5) {
                    updatedNotifications.shift(); // Remove the oldest notification
                }
                console.log(`updatedNotifications: ${updatedNotifications}`)
                return updatedNotifications;
            });
        }
        else{
            setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
            // setNotifications(newNotification);
        }
        console.log(`setNotifications: ${notifications}`)
    };
    const avg_score_data = useCallback(async () => {
        let filters = "All";
        console.log(localStorage.getItem("isEvaluating"))
        if (localStorage.getItem("isEvaluating") === "True") { 
            setAvgScore("Evaluating");
        }
        else{
            try {
                const response = await fetch(
                    `${API_BASE_URL}/call_avg_score_data/?filters=${encodeURIComponent(filters)}`,
                    { method: 'GET' }
                );
                if (!response.ok) {
                    throw new Error('Self Evaluation API call failed.');
                }
                const data = await response.json();
                if (data){
                    setAvgScore(data);
                }
            } catch (error){
                console.error('Error during Self Evaluation:', error);

            }
    }
    },[]);

    useEffect(() => {
        // Initialize WebSocket connection
        const ws = new WebSocket(`${WEB_SOCKET_BASE_URL}/ws`);
    
        ws.onmessage = async (event) => {
            // alert(`Headers Data call Websocket 223`)
            console.log(`Event Data: ${event.data}`);
            console.log(`Type Event Data: ${typeof(event.data)}`);
            let message = event.data;
            if (!message)
            {
                return false;
            }
            
            message = JSON.parse(event.data);
            
            console.log(`Message: ${JSON.stringify(message)}`);
            if (message.message === 'Self Evaluation Process Start') {
                console.log(`Process Start`);
                localStorage.setItem("isEvaluating", "True");
                console.log(localStorage.getItem("isEvaluating"))
                avg_score_data()
                // alert('Process start')
                
            } else if (message.message === 'Self Evaluation Process Completed!') {
                // alert(`SELF EVALUATION PROCESS COMPLETE NOW SET TRUE`)
                setprocess('Self Evaluation');
                let builder_name = '';
                setSelectedBuilder({builder_name});
                setScoreVisible(true);
                let filters = message.filters;
                console.log(`Filters: ${JSON.stringify(filters)}`);
                console.log(`TEST:`)
                if (onSelfEvaluationUpdate) {
                    onSelfEvaluationUpdate(filters); // Update chart with new filters data
                }

                
                await handleWebSocketMessage('Self Evaluation process completed',false);

            }
            else if (message.message === 'Scoring process completed!') {
                console.log('MESSAGE: ',JSON.stringify(message))
                // alert('Headers Scoring Process Complete')
                let builder_name = message.builder_name;
                setprocess('Scoring');
                setSelectedBuilder({builder_name})
                setScoreVisible(true);
                localStorage.setItem("isEvaluating", "False");
                console.log(localStorage.getItem("isEvaluating"))
                avg_score_data()
                await handleWebSocketMessage('Scoring process completed for task ' + new Date().toLocaleTimeString(),true);



            }
        
        };
    
        ws.onerror = (error) => {
            console.error("WebSocket error:", error.message || error);
        };
    
        ws.onclose = () => {
            console.warn("WebSocket connection closed");
        };
    
        ws.onopen = () => {
            console.log("WebSocket connection established");
        };
    
        return () => {
            // Cleanup WebSocket connection on component unmount
            ws.close();
        };
    }, []);

    const handleLogout = async () => {
        try {
            await logoutUser(email);
            localStorage.removeItem('authToken');
            localStorage.removeItem('loggedInUserEmail');
            localStorage.removeItem('is_admin');
            window.location.href = '/login';
        } catch (error) {
            alert('Logout failed. Please try again.');
        }
    };

    const handleSelfEvaluation = async () => {
        setEvaluationPopupVisible(true);
        try {
            const response = await fetch(
                `${API_BASE_URL}/call_self_evaluation/`,
                { method: 'GET' }
            );

            if (!response.ok) {
                throw new Error('Self Evaluation API call failed.');
            }

            const data = await response.json();
            
        } catch (error) {
            console.error('Error during Self Evaluation:', error);
        }
        
    };



    useEffect(() => {
        avg_score_data();
    }, [avg_score_data]);
	const openSelfEvaluationDropDown = (e) => {
	
		// Toggle the current dropdown
		const targetedElement = e.currentTarget.closest('.targeted-element'); // Adjust this selector as needed
		const nextElement = targetedElement?.nextElementSibling; // Get the next sibling dropdown
	
		if (nextElement) {
			console.log(nextElement)
		  if (!nextElement.classList.contains('active')) {
			// Close all currently open dropdowns
			const allDropdowns = document.querySelectorAll('.pof2frms-dropdown.active');
			allDropdowns.forEach(dropdown => dropdown.classList.remove('active'));
			console.log('adfadf')
			nextElement.classList.add('active'); // Open the current dropdown
		  } else {
			console.log('removeclass')
			nextElement.classList.remove('active'); // Close if it's already open
		  }
		}
	
		e.stopPropagation(); // Prevent event bubbling to document click handler
	  };
	
	  useEffect(() => {
		const handleOutsideClick = (e) => {
		  if (!e.target.closest('.pof2frms-dropdown') && !e.target.closest('.targeted-element')) {
			const allDropdowns = document.querySelectorAll('.pof2frms-dropdown.active');
			allDropdowns.forEach(dropdown => dropdown.classList.remove('active'));
		  }
		};
		document.addEventListener('click', handleOutsideClick);
		return () => {
		  document.removeEventListener('click', handleOutsideClick);
		};
	  }, []);
    return (
        <header>
            {/* {test && (<div>Pass</div>)} */}
            
            <div className="po2frm-header-inner">
                <nav>
                    <ul className="po2frms-menu">
                        {isAdmin && (
                            <>
                                <li>
                                    <Link to={"/dashboard"} className={isActive("/dashboard") ? "active" : ""}>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/users"} className={isActive("/users") ? "active" : ""}>
                                        Users
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/builder-rules"} className={isActive("/builder-rules") ? "active" : ""}>
                                        Builder Rules
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/orders-lisiting"} className={isActive("/orders-lisiting") ? "active" : ""}>
                                        Order Listing
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
                <div className="po2frms-actions">
                    <div className="pof2frms-dd">
                        <span
                            className="targeted-element"
                            
                        >
                            <span>Self Evaluation <span className={`pof2frms-tags ${avgScore !== null && avgScore < 90 ? 'red-bg' : ''}`}>
                                {avgScore !== null ? avgScore : '0'}</span></span>
								{isAdmin &&(
									<span onClick={(e) => openSelfEvaluationDropDown(e)}></span>
								)}
                        </span>
                        
						{isAdmin && (
                        <ul className="pof2frms-dropdown">
                            <li><span>Last evaluated: 11/06/2024</span></li>
                            <li><Link data-numbers="93" to="#" onClick={handleSelfEvaluation}>Start Self Evaluation</Link></li>
                            <li><Link data-numbers="999" to={isAdmin ? "/test-cases/" : '#'}>Test Cases</Link></li>
                            <li><Link data-numbers="111" to={isAdmin ? "/dashboard/" : '#'}>Self evaluation history</Link></li>
                        </ul>
                        )}
                    </div>
                    <div className="pof2frms-dd">
                        <span className="targeted-element">
                            <span className="user-avatar">
                                <img src={profileIcon} alt={email} />
                            </span>
                            {email}
							<span onClick={(e) => openSelfEvaluationDropDown(e)}></span>
                        </span>
                        <ul className="pof2frms-dropdown">
                            <li><a href="#" onClick={handleLogout}>Logout</a></li>
                        </ul>
                    </div>
                </div>
            {isEvaluationPopupVisible && (
            <div className="pof2frms-popup-wrapper pof2frms-popup-center" id="score-file">
              <div className="popup-overlay" onClick={closeEvaluationPopup}></div>
              <div className="pof2frms-popup-container">
                <h3>Performing Self Evaluation</h3>
                <p>
                  This will take about 10-15 minutes.<br />
                  We will notify you when scoring is complete
                </p>
                <div className="pof2frms-popup-action">
                  <button type="button" className="pof2frms-activated" onClick={closeEvaluationPopup}>
                    Okay
                  </button>
                </div>
              </div>
            </div>
            )}
            <div className="notify-wrapper">
                {isScoreVisible && notifications.map(notification => (
                                    <ScoringNotify  sendDataToParent={handleMessageFromChild} selectedBuilder={selectedBuilder.builder_name} setScoreVisible={setScoreVisible} message={notification.text} process={process}  />                
                ))}
            </div>

            </div>
        </header>
    );
}

export default Header;
