import React, { useState, useEffect, useCallback } from 'react';
import Header from "./common/Header";
import StatusBox from './StatusBox';
import EvaluationNotify from './EvaluationNotify';
import MultiCheckDropdown from './MultiCheckDropdown';
import LineChart from './TestCaseChart';
import ClockIcon from '../assets/images/clock-icon.svg';
import PencilIcon from '../assets/images/pencil-icon.svg';
import { filter_dashboard_data, retrieve_time_data, get_median_corrections_last_month } from '../api';
import { WEB_SOCKET_BASE_URL } from '../config';

const Dashboard = () => {
  const [isEvaluationPopupVisible, setEvaluationPopupVisible] = useState(false);
  const [isScoreComple, setScoreComple] = useState(false);
  const [isTestCaseVisible, setTestCaseVisible] = useState(false);
  // const options = ['Chesmar', 'CitySide', 'David Weekly - Austin', 'David Weekly - Dallas'];
  const allOption = 'All';
  const [selectedItems, setSelectedItems] = useState([allOption]);
  const [avgTime, setAvgTime] = useState(null);
  const [medianCorrections, setMedianCorrections] = useState(null);
  const [filters, setFilters] = useState(null);
  const [meantime, setMeantime] = useState(null);

  const closeEvaluationPopup = (e) => {
    e.preventDefault();
    setEvaluationPopupVisible(false);
    setScoreComple(true);
  };

  const openEvaluationPopup = (e) => {
    e.preventDefault();
    setEvaluationPopupVisible(true);
  };

  const closeTestCasePopup = () => {
    setTestCaseVisible(false);
  };

  const handleViewDetailsClick = useCallback((e) => {
    e.preventDefault();
    setTestCaseVisible(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log(`Selected Items: ${selectedItems}`);
      await filter_dashboard_data(selectedItems);
    };

    fetchData();
  }, [selectedItems]);

  useEffect(() => {
    const fetchMeantime = async () => {
      try {
        const medianCorrections = await get_median_corrections_last_month();
        const timeData = await retrieve_time_data();
        setMeantime(timeData);
        setMedianCorrections(medianCorrections);
        console.log("Retrieved timeData:", timeData);
        console.log("Retrieved medianCorrections:", medianCorrections);
      } catch (error) {
        console.error("Error retrieving meantime data:", error);
        setMeantime("Error");
      }
    };

    fetchMeantime();
  }, []);

  useEffect(() => {
    if (meantime !== null) {
      console.log("Meantime Updated:", meantime);
    }
  }, [meantime]);

  useEffect(() => {
    const ws = new WebSocket(`${WEB_SOCKET_BASE_URL}/ws`);

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.message === 'Dashboard Process Start') {
        console.log("Dashboard Process Start");
      } else if (message.message === 'Self Evaluation Process Completed!') {
        setAvgTime(meantime); // Ensure meantime is updated before setting avgTime
        setMedianCorrections(message.data.overall_median);
        setFilters(message.data.scoring_history);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error.message || error);
    };

    ws.onclose = () => {
      console.warn("WebSocket connection closed");
    };

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    return () => {
      ws.close();
    };
  }, [meantime]); // Depend on meantime to ensure it is updated before usage

  return (
    <div>
      <Header />
      <main>
        <div className="pof2frms-container">
          <div className="pof2frms-head">
            {isScoreComple && <EvaluationNotify />}
            <h1>Dashboard</h1>
          </div>
          <div className="pof2frms-statuses">
            <StatusBox 
              number={meantime !== null ? meantime : "Loading..."} 
              unit="min" 
              text="Avg time to process batch last 30 days" 
              icon={ClockIcon} 
              color={"green"} 
            />
            <StatusBox 
              number={medianCorrections !== null ? medianCorrections : "Loading..."}  
              unit="corrections" 
              text="Median manual corrections per batch last 30 days" 
              icon={PencilIcon} 
              color={"blue"} 
            />
          </div>
          <div className='pof2frms-selfEvaluationScore'>
            <h2>Self Evaluation Scores</h2>
            <div className="pof2frms-builder">
              <span>Builders</span>
              <MultiCheckDropdown selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>
            </div>
          </div>
          <div className="chartWrapper">
            <LineChart filters_data={filters} view={handleViewDetailsClick} />
          </div>
        </div>
      </main>

      {isEvaluationPopupVisible && (
        <div className="pof2frms-popup-wrapper pof2frms-popup-center" id="score-file">
          <div className="popup-overlay" onClick={closeEvaluationPopup}></div>
          <div className="pof2frms-popup-container">
            <h3>Performing Self Evaluation</h3>
            <p>This will take about 10-15 minutes.<br />We will notify you when scoring is complete</p>
            <div className="pof2frms-popup-action">
              <button type="button" className="pof2frms-activated" onClick={closeEvaluationPopup}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}

      {isTestCaseVisible && (
        <div className="pof2frms-popup-wrapper pof2frms-popup-testcase" id="compare-file">
          <div className="popup-overlay" onClick={closeTestCasePopup}></div>
          <div className="pof2frms-popup-container">
            <a href="#" className="pof2frms-popup-close" aria-label="close-popup" onClick={closeTestCasePopup}></a>
            <h3>Failed Test Cases</h3>
            <p>Builders: All | Score - 90</p>
            <div className="po2frms-table">
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Test Case</th>
                    <th>Problem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Builder A</td>
                    <td>Testcase 1</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder A</td>
                    <td>Testcase 2</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder B</td>
                    <td>Testcase 3</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder C</td>
                    <td>Testcase 4</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
