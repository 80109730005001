import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import './App.css';
import Login from "./components/Login";
import Users from "./components/Users";
import Orders from "./components/Orders";
import TestCases from "./components/TestCases";
import AddTestCases from "./components/AddTestCases";
import SelfEvaluationHistory from "./components/SelfEvaluationHistory";
import CompareBuilderRule from "./components/CompareBuilderRule";
import Dashboard from "./components/Dashboard";
import PerformQA from "./components/PerformQA";
import BuilderRules from "./components/BuilderRules";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Navigate to="/login"/>}/>
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login/>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard/>
                            </ProtectedRoute>
							
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <ProtectedRoute>
                                <Users/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/builder-rules"
                        element={
                            <ProtectedRoute>
                                <BuilderRules/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/orders-lisiting"
                        element={
                                <ProtectedRoute><Orders/></ProtectedRoute>
                                

                            
                            
                        }
                    />
                    <Route
                        path="/self-evaluation-history"
                        element={
                            <ProtectedRoute>
                                <SelfEvaluationHistory />
                            </ProtectedRoute>
                                
                        }
                    />
                    <Route
                        path="/test-cases"
                        element={
                            <ProtectedRoute>
                                <TestCases />
                            </ProtectedRoute>
                                
                        }
                    />
                    <Route
                        path="/add-test-cases"
                        element={
                            <ProtectedRoute>
                                <AddTestCases />
                            </ProtectedRoute>
                                
                        }
                    />
                    <Route
                        path="/perform-qa"
                        element={
                            <ProtectedRoute>
                                <PerformQA />
                            </ProtectedRoute>
                                
                        }
                    />
                    <Route
                        path="/perform-qa"
                        element={
                            <ProtectedRoute>
                                <PerformQA />
                            </ProtectedRoute>
                                
                        }
                    />
                    <Route
                        path="/compare-builder-rule"
						element={
							<ProtectedRoute>
								<CompareBuilderRule />
							</ProtectedRoute>
								
						}
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;